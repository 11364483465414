.HorizontalCell {
  --vkui_internal--side_cell_gap: calc(
    var(--vkui--size_base_padding_horizontal--regular) - var(--vkui--spacing_size_s)
  );

  display: flex;
  font-family: var(--vkui--font_family_base);
}

.HorizontalCell__image {
  display: flex;
  justify-content: center;
}

.HorizontalCell__body {
  text-decoration: none;
  font-family: inherit;
}

/* Перебиваем селектор в Tappable--sizeX-(compact|none) */
.HorizontalCell .HorizontalCell__body {
  border-radius: var(--vkui--size_border_radius--regular);
}

.HorizontalCell__content {
  color: var(--vkui--color_text_primary);
  word-break: break-all;
  text-overflow: ellipsis;
}

.HorizontalCell__subtitle {
  color: var(--vkui--color_text_secondary);
}

.HorizontalCell__content .HorizontalCell__subtitle {
  margin-top: 2px;
}

.HorizontalCell--size-s {
  --vkui_internal--side_cell_gap: calc(
    var(--vkui--size_base_padding_horizontal--regular) - var(--vkui--spacing_size_m)
  );
  --vkui_internal--side_cell_width: calc(72px + var(--vkui_internal--side_cell_gap));

  max-width: 72px;
}

.HorizontalCell__image {
  padding: 4px var(--vkui--spacing_size_s);
}

.HorizontalCell__content {
  padding: 2px 6px 8px;
  text-align: left;
}

.HorizontalCell--size-s .HorizontalCell__image {
  padding: 4px var(--vkui--spacing_size_m);
}

.HorizontalCell--size-s .HorizontalCell__content {
  padding: 2px 4px 8px;
  text-align: center;
}

.HorizontalCell--size-m {
  --vkui_internal--side_cell_width: calc(100px + var(--vkui_internal--side_cell_gap));

  width: 100px;
}

.HorizontalCell--size-l {
  width: auto;
}

.HorizontalCell:first-child::before,
.HorizontalCell:last-child::after {
  content: '';
  min-width: var(--vkui_internal--side_cell_gap);
}

.HorizontalCell--size-s:first-child,
.HorizontalCell--size-s:last-child {
  max-width: var(--vkui_internal--side_cell_width);
}

.HorizontalCell--size-m:first-child,
.HorizontalCell--size-m:last-child {
  width: var(--vkui_internal--side_cell_width);
}
