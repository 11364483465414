.ModalCardBase__container {
  box-sizing: border-box;
  position: relative;
  background: var(--vkui--color_background_modal);
  border-radius: 18px;
  padding: 16px;
  margin-bottom: var(--vkui_internal--safe_area_inset_bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: initial;
  box-shadow: var(--vkui--elevation3);

  --vkui_internal--background: var(--vkui--color_background_modal);
}

.ModalCardBase__container--softwareKeyboardOpened {
  margin-bottom: 0;
}

.ModalCardBase__header,
.ModalCardBase__subheader {
  text-align: center;
  margin: 0;
  padding: 0 8px;
  word-break: break-word;
}

.ModalCardBase__header {
  color: var(--vkui--color_text_primary);
}

.ModalCardBase__subheader {
  color: var(--vkui--color_text_subhead);
}

.ModalCardBase__header + .ModalCardBase__subheader {
  margin-top: 8px;
}

.ModalCardBase__icon {
  margin: 8px auto 16px;
  color: var(--vkui--color_icon_accent);
}

.ModalCardBase__actions {
  display: flex;
  margin-top: 16px;
}

:global(.vkuiInternalUsersStack) + .ModalCardBase__actions {
  margin-top: 24px;
}

.ModalCardBase__header + .ModalCardBase__actions,
.ModalCardBase__subheader + .ModalCardBase__actions {
  margin-top: 32px;
}

/**
 * iOS
 */

.ModalCardBase__dismiss {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  color: var(--vkui--color_icon_secondary);
  justify-content: center;
}

/**
 * iOS
 */
/*
 * TODO [>=6]: зачем этот стиль? Удалить?
*/
.ModalCardBase--ios .ModalCardBase__header:first-child {
  padding-left: 36px;
  padding-right: 36px;
}

/**
 * Android + vkcom
 */

.ModalCardBase--android .ModalCardBase__container,
.ModalCardBase--vkcom .ModalCardBase__container {
  border-radius: 12px;
}

/**
 * Desktop
 */

.ModalCardBase--desktop .ModalCardBase__container {
  border-radius: 8px;
}
