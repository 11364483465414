.View {
  position: relative;
  height: 100%;
  width: 100%;
  word-wrap: break-word;
}

.View--animated,
.View--swiping-back {
  overflow: hidden;
}

.View--animated .View__panel,
.View--animated .View__header,
.View--swiping-back .View__panel,
.View--swiping-back .View__header {
  pointer-events: none;
}

.View__panels {
  height: 100%;
  width: 100%;
}

.View__panel {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  isolation: isolate;
}

.View__panel-in {
  height: 100%;
}

.View--animated .View__panel,
.View--swiping-back .View__panel {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

/**
 * Panel transition
 */
.View__panel--next ~ .View__panel--prev {
  animation: vkui-animation-view-prev-back 0.3s var(--vkui--animation_easing_platform) forwards;
}

.View__panel--prev ~ .View__panel--next {
  animation: vkui-animation-view-next-forward 0.3s var(--vkui--animation_easing_platform);
}

.View--no-motion .View__panel {
  animation: none;
}

@keyframes vkui-animation-view-prev-back {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(80px);
  }
}
@keyframes vkui-animation-view-next-forward {
  from {
    opacity: 0;
    transform: translateY(80px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/**
 * Panel swipe back
 */

.View--ios .View__panel--swipe-back-prev {
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.3);
}

.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-success {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(100%, 0, 0);
}

.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-success {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(0, 0, 0);
}

.View--ios .View__panel--swipe-back-prev.View__panel--swipe-back-failed {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(0, 0, 0);
}

.View--ios .View__panel--swipe-back-next.View__panel--swipe-back-failed {
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  transform: translate3d(-50%, 0, 0);
}

/**
 * Panel transition
 */
.View--ios .View__panel--prev {
  animation: vkui-animation-ios-prev-forward 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next {
  animation: vkui-animation-ios-next-back 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--prev ~ .View__panel--next {
  animation: vkui-animation-ios-next-forward 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next ~ .View__panel--prev {
  animation: vkui-animation-ios-prev-back 0.6s var(--vkui--animation_easing_platform) forwards;
}

.View--ios .View__panel--prev :global(.vkuiInternalPanel__fade),
.View--ios .View__panel--next :global(.vkuiInternalPanel__fade) {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  pointer-events: none;
  z-index: var(--vkui_internal--z_index_panel_header_fade);
}

.View--ios .View__panel--prev :global(.vkuiInternalPanel__fade) {
  animation: vkui-animation-ios-fade-in 0.6s var(--vkui--animation_easing_platform);
}

.View--ios .View__panel--next :global(.vkuiInternalPanel__fade) {
  animation: vkui-animation-ios-fade-out 0.6s var(--vkui--animation_easing_platform) forwards;
}

.View--ios .View__panel--prev ~ .View__panel--next :global(.vkuiInternalPanel__fade),
.View--ios .View__panel--next ~ .View__panel--prev :global(.vkuiInternalPanel__fade) {
  display: none;
}

.View--ios.View--no-motion .View__panel,
.View--ios.View--no-motion .View__panel :global(.vkuiInternalPanel__in) {
  animation: none;
}

/**
 * Active panel
 */
.View--ios .View__panel--active :global(.vkuiInternalPanel__fade) {
  display: none;
}

@keyframes vkui-animation-ios-next-forward {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes vkui-animation-ios-next-back {
  from {
    transform: translate3d(-50%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes vkui-animation-ios-prev-forward {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes vkui-animation-ios-prev-back {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes vkui-animation-ios-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.3;
  }
}
@keyframes vkui-animation-ios-fade-out {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 0;
  }
}
