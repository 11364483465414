.Popover {
  position: relative;
  animation: vkui-popover-fadein 0.2s ease;
  background: var(--vkui--color_background_modal);
  border-radius: var(--vkui--size_border_radius--regular);
  box-shadow: var(--vkui--elevation3);
}

/* Создаём "Safe Zone" */
.Popover::before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--vkui_internal--popover_safe_zone_padding));
  right: calc(-1 * var(--vkui_internal--popover_safe_zone_padding));
  bottom: calc(-1 * var(--vkui_internal--popover_safe_zone_padding));
  left: calc(-1 * var(--vkui_internal--popover_safe_zone_padding));
}

@keyframes vkui-popover-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
