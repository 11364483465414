.Checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
  min-width: var(--vkui--size_field_height--regular);
  min-height: var(--vkui--size_field_height--regular);
  font-family: var(--vkui--font_family_base);
}

/**
 * CMP:
 * FormItem
 */
:global(.vkuiInternalFormItem) .Checkbox {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.Checkbox__icon {
  flex-shrink: 0;
  margin-right: 12px;
}

.Checkbox__icon--on {
  color: var(--vkui--color_icon_accent);
  display: none;
}

.Checkbox__icon--indeterminate {
  color: var(--vkui--color_icon_accent);
  display: none;
}

.Checkbox__icon--off {
  color: var(--vkui--color_icon_tertiary);
}

.Checkbox__content {
  flex-grow: 1;
  color: var(--vkui--color_text_primary);
  word-break: break-word;
  font-weight: var(--vkui--font_weight_accent3);
}

.Checkbox--sizeY-regular:not(.Checkbox--vkcom) .Checkbox__content {
  font-size: var(--vkui--font_headline1--font_size--regular);
  line-height: var(--vkui--font_headline1--line_height--regular);
}

@media (--sizeY-regular) {
  .Checkbox--sizeY-none:not(.Checkbox--vkcom) .Checkbox__content {
    font-size: var(--vkui--font_headline1--font_size--regular);
    line-height: var(--vkui--font_headline1--line_height--regular);
  }
}

.Checkbox--vkcom .Checkbox__content,
.Checkbox--sizeY-compact .Checkbox__content {
  font-size: var(--vkui--font_text--font_size--compact);
  line-height: var(--vkui--font_text--line_height--compact);
}

@media (--sizeY-compact) {
  .Checkbox--sizeY-none .Checkbox__content {
    font-size: var(--vkui--font_text--font_size--compact);
    line-height: var(--vkui--font_text--line_height--compact);
  }
}

.Checkbox__description {
  color: var(--vkui--color_text_secondary);
  display: block;
  margin-bottom: 12px;
  margin-top: 2px;
}

.Checkbox__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.Checkbox__title:last-child {
  margin-bottom: 12px;
}

.Checkbox__titleAfter {
  margin-left: 4px;
  color: var(--vkui--color_icon_tertiary);
}

.Checkbox__input:checked ~ .Checkbox__icon--on {
  display: flex;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--indeterminate,
.Checkbox__input:checked ~ .Checkbox__icon--off {
  display: none;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--indeterminate {
  display: flex;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--on,
.Checkbox__input:indeterminate ~ .Checkbox__icon--off {
  display: none;
}

.Checkbox__input[disabled] ~ * {
  opacity: var(--vkui--opacity_disable_accessibility);
}

/**
 * sizeY COMPACT
 */

.Checkbox--sizeY-compact {
  min-width: var(--vkui--size_field_height--compact);
  min-height: var(--vkui--size_field_height--compact);
}

.Checkbox--sizeY-compact .Checkbox__title {
  margin-top: 8px;
}

.Checkbox--sizeY-compact .Checkbox__title:last-child {
  margin-bottom: 8px;
}

.Checkbox--sizeY-compact .Checkbox__description {
  margin-bottom: 8px;
}

@media (--sizeY-compact) {
  .Checkbox--sizeY-none {
    min-width: var(--vkui--size_field_height--compact);
    min-height: var(--vkui--size_field_height--compact);
  }

  .Checkbox--sizeY-none .Checkbox__title {
    margin-top: 8px;
  }

  .Checkbox--sizeY-none .Checkbox__title:last-child {
    margin-bottom: 8px;
  }

  .Checkbox--sizeY-none .Checkbox__description {
    margin-bottom: 8px;
  }
}

.Checkbox--simple {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
}

.Checkbox--simple .Checkbox__content {
  display: none;
}

.Checkbox--simple .Checkbox__icon {
  margin: 0;
}
