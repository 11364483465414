.Banner {
  isolation: isolate;
  margin: 12px 0;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
  color: var(--vkui--color_text_primary);
}

.Banner__in {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding: 12px 12px 12px 16px;
  background-color: var(--vkui--color_background_secondary);
  border-radius: var(--vkui--size_border_radius--regular);
  overflow: hidden;
}

.Banner__in::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: var(--vkui_internal--thin_border) solid var(--vkui--color_image_border_alpha);
  border-radius: inherit;
  pointer-events: none;
  box-sizing: border-box;
  z-index: var(--vkui_internal--z_index_banner_content);
}

.Banner__before {
  position: relative;
  margin-right: 12px;
  z-index: var(--vkui_internal--z_index_banner_content);
}

.Banner__content {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  z-index: var(--vkui_internal--z_index_banner_content);
}

.Banner__subheader,
.Banner__text {
  color: var(--vkui--color_text_subhead);
}

.Banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: var(--vkui_internal--z_index_banner_background);
}

.Banner__bg,
.Banner__bg > * {
  width: 100%;
  height: 100%;
}

.Banner__aside {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 28px;
  color: var(--vkui--color_icon_tertiary);
}

.Banner__dismiss {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_icon_secondary);
  z-index: var(--vkui_internal--z_index_banner_hover);
}

.Banner__actions {
  margin-top: 12px;
}

.Banner__subheader:not(:first-child),
.Banner__text:not(:first-child) {
  margin-top: 2px;
}

/**
 * Mode "image"
 */
.Banner--mode-image .Banner__in {
  background-color: var(--vkui--color_background_secondary);
}

.Banner--inverted .Banner__dismiss,
.Banner--inverted .Banner__expand,
.Banner--inverted {
  color: var(--vkui--color_text_contrast);
}

.Banner--inverted .Banner__subheader {
  color: var(--vkui--color_text_contrast);
  opacity: 0.72;
}

/**
 * Size "s"
 */
.Banner--size-s .Banner__before {
  margin-left: -4px;
}

/**
 * Size "m"
 */
.Banner--size-m .Banner__in {
  padding: 16px;
}

.Banner--size-m .Banner__subheader:not(:first-child),
.Banner--size-m .Banner__text:not(:first-child) {
  margin-top: 4px;
}

.Banner--ios .Banner__dismiss,
.Banner--ios.Banner--mode-image .Banner__dismiss {
  color: var(--vkui--color_icon_tertiary);
}

/**
 * CMP:
 * FormStatus
 */
:global(.vkuiInternalFormStatus) .Banner__in::before {
  border: 0;
}

:global(.vkuiInternalFormStatus--mode-error) .Banner__in {
  background-color: var(--vkui--color_background_negative_tint);
}

:global(.vkuiInternalFormStatus--mode-error) .Banner__subheader {
  color: var(--vkui--color_background_negative);
}
