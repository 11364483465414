.zag {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: black;
}

.text {
    opacity: 0.5;
    font-size: 14px;
    padding-top: 10px;
    color: black;
}

.opis {
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.color1{
    background-color: "#DFDAFF"
}
.color2{
    background-color: "#FFCADD"
}
.color3{
    background-color: "#D1F1FF"
}
.color4{
    background-color: "#FFE5CB"
}