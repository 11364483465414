.Input {
  position: relative;
  line-height: 20px;
  font-size: 16px;
}

.Input--align-center .Input__el {
  text-align: center;
}

.Input--align-right .Input__el {
  text-align: right;
}

.Input__el {
  height: var(--vkui--size_field_height--regular);
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  border: 0;
  border-radius: inherit;
  appearance: none;
  color: var(--vkui--color_text_primary);
  padding: 0 12px;
  position: relative;
  z-index: var(--vkui_internal--z_index_form_field_element);
  background: transparent;
}

.Input--sizeY-compact .Input__el {
  height: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .Input--sizeY-none .Input__el {
    height: var(--vkui--size_field_height--compact);
  }
}

.Input--hasBefore .Input__el {
  padding-left: 0;
}

.Input--hasAfter .Input__el {
  padding-right: 0;
}

.Input__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Input__el::placeholder {
  color: var(--vkui--color_text_secondary);
  /* Для Firefox */
  opacity: 1;
}

.Input__el:disabled::placeholder {
  color: var(--vkui--color_text_secondary);
}
